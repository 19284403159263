import React, { Component } from 'react'
import { Link, graphql } from 'gatsby'
import moment from 'moment'

import SEO from '../components/seo'
import Subscribe from '../components/subscribe'
import WorkCard from '../components/cards/work-card'
import { ArrowDown, Close } from '../components/icons'

class WorkTemplate extends Component {

  lastScroll = 0

  state = {
    visible: false,
    scrolled: false,
    filter: this.props.pageContext.category || 'All'
  }

  constructor(props) {
    super(props)

    this._toggleFilter = this._toggleFilter.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollDetect)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pageContext.category !== this.props.pageContext.category) {
      this.setState({ filter:this.props.pageContext.category || 'All' })
    }
  }

  scrollDetect = () => {
    let currentScroll = document.documentElement.scrollTop || document.body.scrollTop
    let { scrolled } = this.state

    if (currentScroll > 0 && this.lastScroll <= currentScroll) {
      if (!scrolled) {
        this.setState({ scrolled: true })
      }
    } else {
      if (scrolled) {
        this.setState({ scrolled: false })
      }
    }
    this.lastScroll = currentScroll
  }

  _toggleFilter() {
    if ( this.state.visible === true ) {
      this.setState({visible: false})
    } else {
      this.setState({visible: true})
    }
  }

  render() {
    let { visible, filter, scrolled } = this.state
    let { data } = this.props
    const posts = data.allWordpressWpWork ? data.allWordpressWpWork.edges : []

    let title = 'Work'
    if (this.props.pageContext.category) {
      title = `${this.props.pageContext.category} Work`
    }

    return (
      <>
        <SEO bodyClass="work" title={title} />
        <section className="works">
          <div className={`works__filter ${scrolled ? 'scrolled' : ''}`}>
            <div className="works__inner container">
              <div className="col">
                <h4 onClick={this._toggleFilter}>Filter: <span>{this.state.filter}</span> <ArrowDown colour="#000" /></h4>
              </div>
            </div>
          </div>
          <div className="works__inner works__padding container">
            <div className="works__items">
              { posts.map((el, i) => {
                let props = {
                  key: i,
                  image: el.node.acf.featured_image && el.node.acf.featured_image.localFile && el.node.acf.featured_image.localFile.childImageSharp.fixed.src,
                  title: el.node.title,
                  link: `/work/${el.node.slug}`,
                  author: el.node.author,
                  date: moment(el.node.date).format("MMM, YYYY")
                }
                if (filter !== 'All' && (el.node.categories && !el.node.categories.find(cat => cat.name === filter)) && (el.node.sector && !el.node.sector.find(cat => cat.name === filter)) ) {
                  return false
                }
                return (<WorkCard {...props} />)
              }) }
            </div>
          </div>
          <div className={`${visible ? 'works__filter-drop active' : 'works__filter-drop'}  ${scrolled ? 'scrolled' : ''}`}>
            <div className="works__inner container">
              <span className="close" onClick={this._toggleFilter}><Close colour="#737373" /></span>
              <div className="col">
                <h5>Filter: <span>{filter}</span></h5>
              </div>
              <div className="col">
                <h4>Discipline</h4>
                <ul>
                  {
                    data.allWordpressCategory.edges && data.allWordpressCategory.edges.map((el, i) => {
                      let props = {
                        to: `/work/discipline/${el.node.slug}`,
                        onClick: (event) => {
                          event.preventDefault()
                          this.setState({ filter: event.currentTarget.innerText,  })
                          this._toggleFilter()
                          // Scroll to a certain element
                          document.querySelector('.works').scrollIntoView({
                            behavior: 'smooth',
                            alignTo: true
                          })
                          if (window.location.pathname !== props.to) {
                            window.history.pushState(window.history.state, '', props.to)
                          }
                        }
                      }
                      return (
                        <li key={i}>
                          <Link {...props}>{ el.node.name }</Link>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
              <div className="col">
                <h4>Sector</h4>
                <ul>
                  {
                    data.allWordpressWpSector.edges && data.allWordpressWpSector.edges.map((el, i) => {
                      let props = {
                        to: `/work/sector/${el.node.slug}`,
                        onClick: (event) => {
                          event.preventDefault()
                          this.setState({ filter: event.currentTarget.innerText })
                          this._toggleFilter()
                          // Scroll to a certain element
                          document.querySelector('.works').scrollIntoView({
                            behavior: 'smooth',
                            alignTo: true
                          })
                          if (window.location.pathname !== props.to) {
                            window.history.pushState(window.history.state, '', props.to)
                          }
                        }
                      }
                      return (
                        <li key={i}>
                          <Link {...props}>{ el.node.name }</Link>
                        </li>
                      )
                    })
                  }
                </ul>
              </div>
            </div>
          </div>
        </section>
        <Subscribe />
      </>
    )
  }
}

export default WorkTemplate

export const workQuery = graphql`
  query WorkTemplateQuery($skip: Int = 0, $limit: Int = 99999) {
    allWordpressWpWork(limit: $limit, skip: $skip) {
      edges {
        node {
          title
          slug
          categories {
            name
            slug
          }
          sector {
            name
            slug
          }
          acf {
            featured_image {
              localFile {
                childImageSharp {
                  fixed(width: 1200) {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
    wordpressPage(wordpress_id: { eq: 111 }) {
      title
      slug
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
    }
    allWordpressCategory(sort: {fields: [menu_order], order: ASC}) {
      edges {
        node {
          name
          slug
        }
      }
    }
    allWordpressWpSector(sort: {fields: [menu_order], order: ASC}) {
      edges {
        node {
          name
          slug
        }
      }
    }
  }
`
